import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import buttonStyles from "../components/buttons.module.scss";

const FAQS = () => {
  return (
    <Layout>
      <SEO title="Notion Covers | FAQs" />
      <h2>FAQs</h2>

      <h3>What are Notion covers?</h3>
      <p>
        Notion covers are decorative images that allow you to personalize your
        Notion workspace. Covers appear at the top of any page and can be
        photos, artwork, solid colors, gradients or any other aesthetic that you
        desire.
      </p>

      <h3>What is the ideal size for Notion covers?</h3>
      <p>
        Covers resize dynamically depending on the size of your device or
        browser window. This means there's no one "ideal size". However, Notion
        recommends rectangular images that are at least 1,500 pixels wide.
        Important design elements should be in the center of the frame as the
        edges may be cut off at different screen sizes.
      </p>

      <h3>How can I create my own Notion covers?</h3>
      <p>
        We recommend using Canva or Figma to create Notion covers. Both are free
        tools that allow you to design custom images in your browser.
      </p>

      <h3>Why should I use custom Notion covers?</h3>
      <p>
        Customizing your Notion workspace allows you to create memorable,
        readable content. Notion covers enable you to set up a consistent
        aesthetic across your pages or to implement branding for your company or
        clients.
      </p>

      <hr />
      
      <div style={{textAlign: "center"}}>
        <Link className={buttonStyles.button} to="/">
          Browse Notion covers
        </Link>
      </div>
    </Layout>
  );
};

export default FAQS;
